import React from 'react'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import './layout.css'

const Layout = ({ children }) => {
  return (
    <>
      <div className='content'>
        <Header />
        <div className='contentBody'>{children}</div>
        <Footer />
      </div>
    </>
  )
}

export default Layout
