import { useMemo } from 'react'
import { useEffect } from 'react'

const OnScreenObserver = (options, targetRef) => {
  const optionMemo = useMemo(() => {
    return options
  }, [options])

  useEffect(() => {
    console.log(targetRef)
    const observer = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        } else {
          entry.target.classList.remove('show')
        }
      })
    }, optionMemo)

    const currentTarget = targetRef.current
    if (currentTarget) {
      observer.observe(currentTarget)
    }

    return () => {
      if (currentTarget) {
        observer.unobserve(currentTarget)
      }
    }
  }, [targetRef, optionMemo])
}

export default OnScreenObserver
