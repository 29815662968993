import React from 'react'
import { IconContext } from 'react-icons'
import './iconBox.css'

const iconSize = '4vh'

const IconBox = ({ iconKeyName, iconLibaryComponent, index }) => {
  return (
    <IconContext.Provider
      value={{
        color: '#ff7518',
        className: 'global-class-name',
        size: iconSize,
      }}
    >
      <div className='iconContainer' key={`${iconKeyName} ${index}`}>
        <div>{iconLibaryComponent}</div>
        <div style={{ fontSize: '1.4vh' }}>{iconKeyName}</div>
      </div>
    </IconContext.Provider>
  )
}
export default IconBox
