import { projects } from './projectsInfo'
import { BsArrowDownShort } from 'react-icons/bs'
import IconBox from '../../../lib/iconBox/IconBox'
import OnScreenMultipleItemObserver from '../../../lib/observer/multipleItemObserver'
import { useRef } from 'react'
import './projectContent.css'

const ProjectContent = () => {
  const GenerateProjectContent = () => {
    const itemsRef = useRef([])
    OnScreenMultipleItemObserver(
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
      },
      itemsRef
    )
    const projectList = Object.entries(projects)

    const result = projectList.map((project, i) => {
      const projectContent = project[1]
      return (
        // note: need to solve the ref issue
        <div
          className='simpleContainer hidden'
          ref={(e) => (itemsRef.current[i] = e)}
          key={`project ${i}`}
        >
          <div className='projectContainer'>
            <div className='projectProfileImageContainer'>
              <img src={projectContent.profilePicture} alt={project[0]} />
            </div>
            <div className='projectTitle'>{projectContent.title}</div>
            <div className='contentTitle'>Content</div>
            <div className='projectContent'>{projectContent.content}</div>
            <div className='projectTasks'>
              <div className='taskTitle'>Tasks</div>
              {projectContent.Tasks.map((task) => (
                <div className='task'> {task} </div>
              ))}
            </div>
            <div className='projectAchievements'>
              <div className='achievementTitle'>Achievements</div>
              {projectContent.Achievements.map((achievement) => (
                <div className='achievement'> {achievement} </div>
              ))}
            </div>
          </div>
          {projectContent.lastProject ? (
            <></>
          ) : (
            <IconBox iconLibaryComponent={<BsArrowDownShort />} />
          )}
        </div>
      )
    })
    return result
  }

  return (
    <>
      <div>{GenerateProjectContent()}</div>
    </>
  )
}

export default ProjectContent
