import { useMemo } from 'react'
import { useEffect } from 'react'

const OnScreenMultipleItemObserver = (options, itemsRef) => {
  const optionMemo = useMemo(() => {
    return options
  }, [options])

  useEffect(() => {
    const observer = new IntersectionObserver((entires) => {
      entires.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show')
        } else {
          entry.target.classList.remove('show')
        }
      })
    }, optionMemo)

    const currentTarget = itemsRef.current
    if (currentTarget) {
      currentTarget.forEach((target) => {
        if (target) {
          observer.observe(target)
        }
      })
    }

    return () => {
      if (currentTarget) {
        currentTarget.forEach((target) => {
          if (target) {
            observer.unobserve(target)
          }
        })
      }
    }
  }, [itemsRef, optionMemo])
}

export default OnScreenMultipleItemObserver
