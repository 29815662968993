import vre from '../../../static/images/vre.png'
import aerometrex from '../../../static/images/aerometrex.png'
import csiro from '../../../static/images/csiro.png'

export const projects = {
  'CSIRO & Data 61': {
    title: 'CSIRO & Data61',
    content:
      'Set up the cameras, lighting and synchronisation control system, and optimize the camera and lighting configuration. Reconstruct 3D images using the individual images acquired from the 60 cameras. Develop deep learning models for automated detection of skin lesions',
    Tasks: [
      'Developed a 3D Whole Body Imaging system for automated Skin Cancer Diagnosis.',
      'Reconstructed a 3D model via images acquired from 60 cameras by software built from source code.',
      'Calibrated deep learning models for human-background segmentation and skin lesion detection in Python.',
    ],
    Achievements: [
      'Developed a 3D Whole Body Imaging and Lesion Detection System for Automated Skin Cancer Diagnosis.',
    ],
    profilePicture: csiro,
    lastProject: false,
  },
  Aerometrex: {
    title: 'Aerometrex',
    content:
      'Migrated binary deep learning, geographic inference model which was used to segment the solar panel to AWS Cloud',
    Tasks: [
      'Migrated geographic inference model code to AWS Cloud based system in Python.',
      'Integrated Celery task queue with AWS SQS to decouple cropping and inference tasks in original code.',
      'Containerized codes using Docker and deployed to AWS EKS Cluster built by Pulumi lac SDK in Typescript',
    ],
    Achievements: [
      'Helped the company win the AWS 2022 Machine learning Finalist.',
    ],
    profilePicture: aerometrex,
    lastProject: false,
  },

  'Westfield Vehicle Recognise Engine(VRE)': {
    title: 'Westfield Vehicle Recognise Engine(VRE)',
    content:
      'Replaced Westfield legacy car parking system with the brand new Vehicle Recognise Engine(VRE). Optimised the VRE in ReactJs, NodeJs, and Graphql. The VRE is currently functioning in the Westfield Bondi car park',
    Tasks: [
      'Redesigned the static reporting page to dynamic accuarcy monitoring page.',
      'Tested recognition accuracy by designing an auto simulation function to test tunning and optimisation change.',
      'Monitored system metrics by creating Prometheus metric endpoint and customised alerting rule in Grafana.',
      'Increased recognition accuracy by 1% by designing an exit land delay function in the Recognise Engine.',
      'written bash script to automatically clean up the disc sapce and upload image files to GCP Cloud Storge.',
    ],
    Achievements: [
      'The brand new Vehicle Recognise Engine(VRE) has completely replaced the legacy car parking system in Westfield Bondi.',
    ],
    profilePicture: vre,
    lastProject: true,
  },
}
