import React from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import './experienceContent.css'
import SchoolIcon from '@mui/icons-material/School'
import WorkIcon from '@mui/icons-material/Work'

const ExperienceContent = () => {
  const educationStyles = {
    backgroundColor: 'rgba(0,0,0,.5)',
    fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
    color: '#EEEEEE',
  }
  const educateIcon = {
    backgroundColor: 'rgba(0,0,0)',
    color: '#EEEEEE',
  }

  const contentStyles = {
    backgroundColor: 'rgba(0,0,0,.5)',
    fontFamily: "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
    color: '#DCB579',
  }

  const iconStyles = {
    backgroundColor: 'rgba(0,0,0)',
    color: '#DCB579',
  }

  return (
    <div className='experience'>
      <VerticalTimeline
        lineColor='#B48D51'
        layout='2-columns'
      >
        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2015-2020'
          icon={<SchoolIcon />}
          iconStyle={educateIcon}
          contentStyle={educationStyles}
        >
          <h3 className='vertical-timeline-element-title'>
            University Of New South Wales(UNSW)
          </h3>
          <h5 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h5>
          <h4>Bachelor of Electrical Engineering, 1 class Honour</h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2019-2020'
          icon={<WorkIcon />}
          iconStyle={iconStyles}
          contentStyle={contentStyles}
        >
          <h3 className='vertical-timeline-element-title'>
            CSIRO & Data61 Summer Research Scholarship
          </h3>
          <h4 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h4>
          <h4>Computer Vision research</h4>
          <h5>Python, Bash script, Mashroom</h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2020-2022'
          icon={<SchoolIcon />}
          iconStyle={educateIcon}
          contentStyle={educationStyles}
        >
          <h3 className='vertical-timeline-element-title'>
            University Of New South Wales(UNSW)
          </h3>
          <h4 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h4>
          <h4>Master of Information Technology</h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2022-present'
          icon={<WorkIcon />}
          iconStyle={iconStyles}
          contentStyle={contentStyles}
        >
          <h3 className='vertical-timeline-element-title'>Servian</h3>
          <h4 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h4>
          <h4>Software Engineer</h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2021/12 - 2022/03'
          icon={<WorkIcon />}
          iconStyle={iconStyles}
          contentStyle={contentStyles}
        >
          <h3 className='vertical-timeline-element-title'>Aerometrex</h3>
          <h5 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h5>
          <h4>DevOps Engineer</h4>
          <h5>Python, Docker, AWS(S3, ECR, EKS), K8S, Pulumi, Typescript</h5>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className='vertical-timeline-element--education'
          date='2022/05 - present'
          icon={<WorkIcon />}
          iconStyle={iconStyles}
          contentStyle={contentStyles}
        >
          <h3 className='vertical-timeline-element-title'>
            Scentre Group (Westfield)
          </h3>
          <h5 className='vertical-timeline-element-subtitle'>
            Sydney, Australia
          </h5>
          <h4>Software Engineer</h4>
          <h5>
            ReactJS, NodeJS, GraphQL, Prisma, PostgresSQL, GCP, Bash Script,
            Segment
          </h5>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  )
}

export default ExperienceContent
