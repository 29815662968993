import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import Experience from './pages/Experience'
import Projects from './pages/Projects'

function App() {
  return (
    <>
      <div>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/career' element={<Experience />} />
          <Route path='/projects' element={<Projects />} />
        </Routes>
      </div>
    </>
  )
}

export default App
