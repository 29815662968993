import React from 'react'
import Layout from '../lib/layout/Layout'
import Particle from '../lib/particle/Particle'
import HomeContent from '../components/content/homeContent/HomeContent'
import SkillContent from '../components/content/skillContent/SkillContent'

const Home = () => {
  return (
    <div>
      <Layout>
        <Particle />
        <HomeContent />
        <SkillContent />
      </Layout>
    </div>
  )
}

export default Home
