import './skillContent.css'
import { FaReact, FaNodeJs } from 'react-icons/fa'
import { TbBrandNextjs } from 'react-icons/tb'
import { DiCss3, DiHtml5, DiMysql, DiPostgresql } from 'react-icons/di'
import {
  SiSpring,
  SiGraphql,
  SiPrisma,
  SiDocker,
  SiKubernetes,
  SiAmazonaws,
  SiGooglecloud,
} from 'react-icons/si'
import IconBox from '../../../lib/iconBox/IconBox'
import OnScreenObserver from '../../../lib/observer'
import { useRef } from 'react'

const frontendSkills = {
  ReactJs: <FaReact />,
  NextJs: <TbBrandNextjs />,
  CSS: <DiCss3 />,
  HTML: <DiHtml5 />,
}

const backendSkills = {
  NodeJs: <FaNodeJs />,
  'Java Spring Boot': <SiSpring />,
  GraphQL: <SiGraphql />,
  Prisma: <SiPrisma />,
}

const databaseSkills = {
  MySQL: <DiMysql />,
  PostgreSQL: <DiPostgresql />,
}

const devOpsSkills = {
  Docker: <SiDocker />,
  Kubernete: <SiKubernetes />,
  AWS: <SiAmazonaws />,
  GCP: <SiGooglecloud />,
}

const skillToIcons = (skills) => {
  const skillListsArray = Object.entries(skills)

  const result = skillListsArray.map((entry, index) => {
    const skillName = entry[0]
    const skillLibraryComponent = entry[1]
    return (
      <IconBox
        iconKeyName={skillName}
        iconLibaryComponent={skillLibraryComponent}
        index={index}
      />
    )
  })
  return result
}

const SkillContent = () => {
  const targetRef = useRef(null)
  OnScreenObserver(
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.3,
    },
    targetRef
  )

  return (
    <>
      <div className='skillContent hidden' ref={targetRef} key='skillContent'>
        <div className='frontendSection' key='frontendSectionKey'>
          <div className='frontEndHeader'>Frontend</div>
          <div className='frontendSkill'>{skillToIcons(frontendSkills)}</div>
        </div>

        <div className='backendSection' key='backendSectionKey'>
          <div className='backendHeader'>Backend</div>
          <div className='backendSkill'>{skillToIcons(backendSkills)}</div>
        </div>

        <div className='databaseSection' key='databaseSectionKey'>
          <div className='databaseHeader'>Database</div>
          <div className='databaseSkill'>{skillToIcons(databaseSkills)}</div>
        </div>

        <div className='devOpsSection' key='devOpsSectionKey'>
          <div className='devOpsHeader'>DevOps</div>
          <div className='devOpsSkills'>{skillToIcons(devOpsSkills)}</div>
        </div>
      </div>
    </>
  )
}

export default SkillContent
