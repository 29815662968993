import { Avatar } from '@mui/material'
import IconBox from '../../../lib/iconBox/IconBox'
import profile from '../../../static/images/profile.jpg'
import { BsArrowDownShort } from 'react-icons/bs'
import './homeContent.css'

//TODO: remove afator
const HomeContent = () => {
  return (
    <>
      <div className='homeContent'>
        <div className='profileAvator'>
          <Avatar
            sx={{
              height: '10vh',
              width: '10vh',
            }}
            src={profile}
          />
        </div>
        <div className='description'>
          <h2> About Me </h2>
          <p>
            My name is Sam, I am currently working as a Software Engineer for an
            Consultant company. I finished both my Bachelor of Electrical
            Engineering with 1 class Honour and Master of Information Technology
            from UNSW.
          </p>

          <p>
            I did my first Computer Vision Internship at CSIRO & Data61 and I
            became interested in coding after that. So, I decided to switch my
            career and started to study Master of Information Technology degree.
          </p>

          <p>
            I am currently working as a Software Engineer for an Consultant
            company and I am interested in Full Stack Development, Devops and
            Machine Learning.
          </p>

          <p></p>
        </div>
        <div className='skillSet'>
          <div className='skillSetHeader'>
            {' '}
            Scroll Down to checkout my skills
          </div>
          <IconBox iconLibaryComponent={<BsArrowDownShort />} />
        </div>
      </div>
    </>
  )
}

export default HomeContent
