import React from 'react'
import Layout from '../lib/layout/Layout'
import Particle from '../lib/particle/Particle'
import ExperienceContent from '../components/content/experienceContent/ExperienceContent'

const Experience = () => {
  return (
    <div>
      <Layout>
        <Particle />
        <ExperienceContent />
      </Layout>
    </div>
  )
}

export default Experience
