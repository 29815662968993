import React from 'react'
import ProjectContent from '../components/content/projectContent/ProjectContent'
import Layout from '../lib/layout/Layout'
import Particle from '../lib/particle/Particle'

const Projects = () => {
  return (
    <div>
      <Layout>
        <Particle />
        <ProjectContent />
      </Layout>
    </div>
  )
}

export default Projects
