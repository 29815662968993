import React from 'react'
import './footer.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import GitHubIcon from '@mui/icons-material/GitHub'
import MailOutlineIcon from '@mui/icons-material/MailOutline'

const Footer = () => {
  // TODO: add the link to environment variable
  const linkedinLink = 'https://www.linkedin.com/in/sam-yang-792814163/'
  const githubLink = 'https://github.com/Samyang7'

  return (
    <>
      <div className='footer'>
        <div className='footerLeft'>
          <MailOutlineIcon />
          <div className='footerEmail'>samyangxh@gmail.com</div>
        </div>

        <div className='footerRight'>
          {/* <Link to={linkedinLink} className='footerLink'> </Link> */}
          <a
            href={linkedinLink}
            className='footerLink'
          >
            <LinkedInIcon className='footerIcon' />
          </a>
          {/* <a href={instagramLink} className='footerLink'>
            <InstagramIcon className='footerIcon'/>
          </a> */}
          <a
            href={githubLink}
            className='footerLink'
          >
            <GitHubIcon className='footerIcon' />
          </a>
        </div>
      </div>
    </>
  )
}

export default Footer
