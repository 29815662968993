import React from 'react'
import { Link } from 'react-router-dom'
import './header.css'

const Header = () => {
  return (
    <>
      <div className='navbar'>
        <Link to='/' className='navLink'>
          {' '}
          Home{' '}
        </Link>
        {/* <Link to="/projects" className='navLink'> Projects </Link> */}
        <Link to='/projects' className='navLink'>
          {' '}
          Projects{' '}
        </Link>
        <Link to='/career' className='navLink'>
          {' '}
          Career{' '}
        </Link>
      </div>
    </>
  )
}

export default Header
